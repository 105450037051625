/* a�^�O�̃X�N���[����~&�X���[�X�X�N���[��
----------------------------------------------------------------------*/
$(function () {
  // #�Ŏn�܂�A���J�[���N���b�N�����ꍇ�ɏ���
  $('a[href^=#]:not()').on('click', function () {
    var w = $(window).width();
    var x = 768;
    var offs = 0;
    if (w <= x) {
      offs = 0;
    }
    // �X�N���[���̑��x
    var speed = 400; // �~���b
    // �A���J�[�̒l�擾
    var href = $(this).attr('href');
    // �ړ�����擾
    var target = $(href == "#" || href == "" ? 'html' : href);
    // �ړ���𐔒l�Ŏ擾
    var position = target.offset().top - offs;
    // �X���[�X�X�N���[��
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
});

/* �y�[�W�g�b�v
----------------------------------------------------------------------*/
$(function () {
  var topBtn = $('#page_top');
  var fixFooter = $('.fix-sp-cta');
  topBtn.hide();
  fixFooter.hide();
  //�X�N���[����100�ɒB������{�^���\��
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
      fixFooter.fadeIn();
    } else {
      topBtn.fadeOut();
      fixFooter.fadeOut();
    }
  });
  //�X�N���[�����ăg�b�v
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 300);
    return false;
  });
});

$(function () {
  $('#confirmBtn').click(function () {
    event.preventDefault()

    $('#nameValidMessage').removeClass('active')
    $('#telValidMessage').removeClass('active')

    if ($('#fullNameInput').val() === '' || $('#telNumberInput').val() === '') {
      if ($('#fullNameInput').val() === '') $('#nameValidMessage').addClass('active')
      if ($('#telNumberInput').val() === '') $('#telValidMessage').addClass('active')

      var speed = 400 // スクロールスピード
      var target = $('#contact')
      if (target.length) {
        var position = target.offset().top
        $('body,html').animate({
          scrollTop: position
        }, speed, 'swing')
      }

      return
    }

    $('#nameConfirmInput').text($('#fullNameInput').val())
    $('#telConfirmInput').text($('#telNumberInput').val())
    $('#emailConfirmInput').text($('#emailInput').val())
    $('#zipCodeConfirmInput').text($('#zipInput').val())
    $('#addressConfirmInput').text($('#addressInput').val())
    $('#contentConfirmInput').text($('#contentInput').val())


    $('#contactModal').addClass('is-active')

  })

  $('#backBtn').click(function () {
    $('#contactModal').removeClass('is-active')
  })

  $('#submitBtn').click(function () {
    // フォームを非同期で送信
    $.ajax({
      type: 'POST',
      url: '/mail',
      data: {
        name: $('#fullNameInput').val(),
        tel: $('#telNumberInput').val(),
        email: $('#emailInput').val(),
        zip: $('#zipInput').val(),
        address: $('#addressInput').val(),
        content: $('#contentInput').val()
      },
      dataType: 'json'
    }).done(function () {
      $('#contactConfirmTitle').text('送信完了')
      // confirm-itemをすべて非表示
      $('.confirm-item').hide()
      $('#submitBtn').hide()
      $('#backBtn').hide()
      $('#closeBtn').show()
    }).fail(function () {
      $('#contactConfirmTitle').text('送信失敗')
      $('.confirm-item').hide()
      $('#submitBtn').hide()
      $('#backBtn').hide()
      $('#closeBtn').show()
    })
  })

  $('#closeBtn').click(function () {
    $('.confirm-item').show()
      $('#submitBtn').show()
      $('#backBtn').show()
      $('#contactModal').removeClass('is-active')

      $('#fullNameInput').val('')
      $('#telNumberInput').val('')
      $('#emailInput').val('')
      $('#zipInput').val('')
      $('#addressInput').val('')
      $('#contentInput').val('')
  })

  // 要素が画面内に入ったかをチェックする関数
  // クラス名を引数として受け取る
  function checkVisibility(className) {
    $(className).each(function() {
      const $element = $(this)
      const elementTop = $element.offset().top
      const elementBottom = elementTop + $element.outerHeight()

      const viewportTop = $(window).scrollTop()
      const viewportBottom = viewportTop + $(window).height()

      // 要素がビューポートの下端から100px内に入ったか判定
      // この値を増やすことで、より下で反応させることができる
      const threshold = 100; // 反応させたい距離（px）

      // 要素が画面内に入ったか判定
      if (elementBottom > viewportTop && elementTop < (viewportBottom - threshold)) {
        $element.addClass('visible')
      } else {
        // スクロールアウトした要素を再度非表示にする場合は以下を有効化
        // $element.removeClass('visible')
      }
    });
  }

  // スクロールとリサイズイベントに関数をバインドし、引数としてクラス名を渡す
  $(window).on('scroll resize', function() {
    checkVisibility('.fadeIn')
    checkVisibility('.fadeBg')
  })

  // 初期ロード時にも実行し、引数としてクラス名を渡す
  checkVisibility('.fadeIn')
  checkVisibility('.fadeBg')

  $('#hamburgerMenu').click(function() {
    $('body').toggleClass('open')
    $('#drawerMenu').toggleClass('open')
    $('.Hamburger-menu__line').toggleClass('open')
  })

  $('.Drawer-menu__link').click(function() {
    $('body').removeClass('open')
    $('#drawerMenu').removeClass('open')
    $('.Hamburger-menu__line').removeClass('open')
  })
})