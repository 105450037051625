window.addEventListener("DOMContentLoaded", ()=>{
  let today = new Date();
  let hour = today.getHours();
  let minute = today.getMinutes();
  let text = `<span>${hour}</span>時<span>${minute}</span>分現在`;
  let timeArea = document.querySelectorAll(".today")
  
  for (let i = 0; i < timeArea.length; i++) {
    timeArea[i].innerHTML = text
  }
  
});
